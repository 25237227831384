import axios from 'axios';
import { SessionChecker } from './session-checker';
import languageService from './language-service';

axios.defaults.baseURL = '';
axios.defaults.headers.common['Content-Type'] = 'application/json';

let sessionChecker;

export function initializeSessionChecker(router) {
    sessionChecker = new SessionChecker(router);
    sessionChecker.setupRouterGuard();
    return sessionChecker;
}

export function getSessionChecker() {
    return sessionChecker;
}

axios.interceptors.response.use(
    response => response,
    async error => {
        if (error.response) {
            if (error.response.status === 401) {
                const isLoginAttempt = error.config.url.includes('/login/auth') ||
                    error.config.url.includes('/connect/google') ||
                    error.config.url.includes('/login/check-google');
                const checker = getSessionChecker();

                if (checker && (
                    checker.isPublicRoute(window.location.pathname) ||
                    window.location.pathname.includes('/connect/google') ||
                    window.location.pathname.includes('/login/check-google')
                )) {
                    return Promise.reject(error);
                }

                if (isLoginAttempt) {
                    return Promise.reject(error);
                } else {
                    localStorage.removeItem('user');
                    const loginUrl = languageService.updatePathLanguage('/login?session_expired=true');

                    if (checker &&
                        checker.isProtectedRoute(window.location.pathname) &&
                        !window.location.pathname.includes('/login')) {
                        window.location.replace(loginUrl);
                    }
                }
            }

            if (error.response.status === 403) {
                const errorUrl = languageService.updatePathLanguage('/error/forbidden');
                window.location.replace(errorUrl);
            }
        }

        return Promise.reject(error);
    }
);

export default axios;