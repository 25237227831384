import axios from 'axios';

export class LanguageService {
    constructor() {
        const storedLang = localStorage.getItem('preferredLanguage');
        this.currentLanguage = (storedLang && ['en', 'de'].includes(storedLang)) ? storedLang : 'en';
        this.isInitialized = false;
    }

    async initialize() {
        if (this.isInitialized) return;

        try {
            const response = await axios.get('/api/language');
            const serverLanguage = response.data.locale;

            if (serverLanguage && this.currentLanguage !== serverLanguage) {
                await this.setLanguage(serverLanguage, false);
            }
        } catch (error) {
            console.error('Failed to initialize language:', error);
        } finally {
            this.isInitialized = true;
        }
    }

    async setLanguage(lang, updateServer = true) {
        if (!['en', 'de'].includes(lang)) {
            console.error('Invalid language:', lang);
            return false;
        }

        if (updateServer) {
            try {
                await axios.post(`/api/language/${lang}`);
            } catch (error) {
                console.error('Failed to update server language:', error);
                return false;
            }
        }

        this.currentLanguage = lang;
        localStorage.setItem('preferredLanguage', lang);

        // Force session update
        document.cookie = `LOCALE=${lang}; path=/; SameSite=Lax`;

        return true;
    }

    getLanguage() {
        return this.currentLanguage;
    }

    updatePathLanguage(path) {
        const langMatch = path.match(/^\/(en|de)\//);
        if (!langMatch) {
            return `/${this.currentLanguage}${path.startsWith('/') ? path : '/' + path}`;
        }
        return `/${this.currentLanguage}${path.substring(3)}`;
    }
}

export const languageService = new LanguageService();
export default languageService;